<template>
  <div class="addQuestion">
    <QuestionForm v-model:type="addParams.type" v-model:title="addParams.title" v-model:score="addParams.score" v-model:answer="addParams.answer" v-model:analysis="addParams.analysis" v-model:contents="contents" @add="submit" />
    <div class="list">
      <div>
        <span>题型</span>
        <select v-model="select.type" @change="typeChange">
          <option name="type" value="single">单选题</option>
          <option name="type" value="multi">多选题</option>
          <option name="type" value="ask">问答题</option>
          <option name="type" value="judge">判断题</option>
          <option name="type" value="写作">写作</option>
        </select>
      </div>
      <table>
        <tr>
          <th>id</th>
          <th>题型</th>
          <th>题目</th>
          <th>题选</th>
          <th>分值</th>
          <th>答案</th>
          <th>解析</th>
          <th>操作</th>
        </tr>
        <tr v-for="item in listData" :key="item.id">
          <td>{{item.id}}</td>
          <td>{{item.type}}</td>
          <td>
            <p>{{item.title}}</p>
          </td>
          <td>
            <p>{{item.content}}</p>
          </td>
          <td>{{(item.score)*1}}</td>
          <td>
            <p>{{item.answer}}</p>
          </td>
          <td>
            <p>{{item.analysis}}</p>
          </td>
          <td>
            <button @click="edit(item)">修改</button>
            <button class="del" @click="deleteForm(item.id)">删除</button>
          </td>
        </tr>
      </table>
      <!-- 分页 -->
      <!-- <ul>
        <li v-for="p in totalPage" :key="p" @click="changePage(p)" :class="{ active: p === select.page }">
          <span>{{p}}</span>
        </li>
      </ul> -->
      <pagenation
        :totalPage="total"
        :current="current"
        :panelNumber="10"
        @changePage="changePage"
        @previous="previous"
        @next="next"/>
    </div>
    <modal :show="modalShow" @close="modalShow=false">
      <QuestionForm
        v-model:type="editParams.type"
        v-model:title="editParams.title"
        v-model:score="editParams.score"
        v-model:answer="editParams.answer"
        v-model:analysis="editParams.analysis"
        v-model:contents="editContents"
        @add="editSubmit" />
    </modal>
  </div>
</template>
<script>
// import axios from 'axios'
import { add, list, update, deleteItem } from '@/api/question.js'
import QuestionForm from '@/components/QuestionForm.vue'
import modal from '@/components/Modal.vue'
import pagenation from '@/components/Pagenation.vue'
export default {
  components: {
    QuestionForm,
    modal,
    pagenation
  },
  data() {
    return {
      addParams: {
        type: 'single',
        title: '',
        score: '1',
        answer: '',
        analysis: '',
        content: []
      },
      editParams: {
        type: '',
        title: '',
        score: '',
        answer: '',
        analysis: '',
        content: []
      },
      contents: '',
      editContents: '',
      modalShow: false,
      id: '',

      select: {
        type: '',
        page: 1
      },
      listData: [],
      // totalPage: 0,
      data: null,
      total: 0,
      current: 1
    }
  },
  computed: {
    addContent() {
      this.addParams.content.push(...this.contents.split('\n'))
      return this.addParams
    },
    updateParam() {
      this.editParams.content.push(...this.editContents.split('\n'))
      return this.editParams
    }
  },
  created() {
    this.requestList(this.select)
  },
  methods: {
    submit() {
      // console.log(this.addContent, this.addParams)
      add(this.addContent).then(res => {
        if (res.status === 200) {
          this.select.page = 1
          this.requestList(this.select)
        }
      })
    },

    typeChange(e) {
      this.select.type = e.target.value
      this.select.page = 1
      this.requestList(this.select)
    },

    // 请求list
    requestList(params) {
      list(params).then(res => {
        if (res.status === 200) {
          this.listData = res.data.data
          this.total = res.data.meta.pagination.total_pages
        }
      }, err => {
        console.log(err)
      })
    },
    // changePage(currentPage) {
    //   this.select.page = currentPage
    //   this.requestList(this.select)
    // },
    changePage(p) {
      this.current = p
      this.select.page = p
      this.requestList(this.select)
    },
    previous(p) {
      this.current = p
      this.select.page = p
      this.requestList(this.select)
    },
    next(p) {
      this.current = p
      this.select.page = p
      this.requestList(this.select)
    },

    edit(data) {
      this.modalShow = true
      this.editParams = {
        type: data.type,
        title: data.title,
        score: data.score,
        answer: data.answer,
        analysis: data.analysis,
        content: []
      }
      this.editContents = data.content.join('\n')
      this.id = data.id
    },
    // 修改
    editSubmit() {
      update(this.updateParam, this.id).then(res => {
        if (res.status === 200) {
          this.requestList(this.select)
          this.modalShow = false
        }
      })
    },
    deleteForm(id) {
      if (confirm('您确定要删除吗')) {
        deleteItem(id).then(res => {
          if (res.status === 200) {
            this.requestList(this.select)
          }
        })
      } else {
        return false
      }
    }
  }
}

</script>
<style scoped lang="less">
.addQuestion {
  .list {
    width: 100%;
    margin: 20px auto;

    div {
      width: 100%;
      margin: 10px auto;
    }

    table {
      width: 100;

      td {
        max-width: 300px;
        overflow-x: auto;
        overflow: hidden;

        button {
          border: none;
          background: green;
          color: #fff;
        }

        .del {
          background: red;
        }

        p {
          width: 100%;
          height: 50px;
          overflow: auto;
        }
      }
    }

    ul {
      width: 100%;
      list-style: none;

      li {
        display: inline-block;
        margin: 10px 5px;
        background: #006fff;

        span {
          padding: 5px 10px;
          color: #fff;
        }
      }

      .active {
        background: #00c1ff;
      }
    }
  }
}

</style>
