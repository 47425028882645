<template>
  <div class="form">
    <form>
      <div>
        <span>省:</span>
        <select :value="province" @change="provinceChange">
          <option name="province" v-for="p in address" :key="p.id" :value="p.province">{{p.province}}</option>
        </select>
        <!-- <input type="text" value="贵州省"> -->
        <span>市:</span>
        <select :value="city" :i="cityIndex" @change="cityChange($event)">
          <option name="city" v-for="(item, index) in citys" :key="item.id" :value="item.district" :i="index">{{item.district}}</option>
        </select>
        <span>县／区:</span>
        <select :value="district" @change="districtChange" placeholder="请选择县／区">
          <option v-for="i in countys" :key="i" :value="i">{{i}}</option>
        </select>
      </div>
      <div>
        <label>
          <span>list url:</span>
          <input class="input_url" type="text" name="list_url" :value="list_url" @input="$emit('update:list_url', $event.target.value)" placeholder="请填写list的url">
          <em @click="$emit('clearUrl', '')">X</em>
        </label>
        <label>
          <span>list class/id:</span>
          <input type="text" name="list_selector" :value="list_selector" @input="$emit('update:list_selector', $event.target.value)">
        </label>
      </div>
      <div>
        <label>
          <span>detail title class/id:</span>
          <input type="text" name="title_selector" :value="title_selector" @input="$emit('update:title_selector', $event.target.value)">
        </label>
        <label>
          <span>date class/id:</span>
          <input type="text" name="pub_date_selector" :value="pub_date_selector" @input="$emit('update:pub_date_selector', $event.target.value)">
        </label>
        <label>
          <span>conten class/id:</span>
          <input type="text" name="content_selector" :value="content_selector" @input="$emit('update:content_selector', $event.target.value)">
        </label>
      </div>
      <div>
        <label>
          <span>附件 class/id:</span>
          <input type="text" name="attachment_selector" :value="attachment_selector" @input="$emit('update:attachment_selector', $event.target.value)">
        </label>
        <label>
          <span>来源:</span>
          <input type="text" name="source" :value="source" @input="$emit('update:source', $event.target.value)">
        </label>
        <!-- <label>
          <span>部门 class/id:</span>
          <input type="text" name="department_selector" :value="department_selector">
        </label> -->
      </div>
      <div>
        <input type="submit" value="提交" @click.prevent="$emit('add')">
      </div>
    </form>
  </div>
</template>

<script>
import addr from '@/util/address.js'
export default {
  name: 'FormAdd',
  props: {
    province: String,
    city: String,
    district: String,
    list_url: String,
    list_selector: String,
    pub_date_selector: String,
    title_selector: String,
    // department_selector: String,
    content_selector: String,
    attachment_selector: String,
    source: String,
    countys: Array,
    citys: Array
  },
  data() {
    return {
      cityIndex: 0
    }
  },
  emits: ['update:province', 'update:city', 'update:district', 'update:list_url', 'update:list_selector', 'update:pub_date_selector', 'update:title_selector', 'update:content_selector', 'update:attachment_selector', 'update:source', 'add', 'clearUrl', 'setCounty', 'setCity'],
  computed: {
    address() {
      // console.log(addr)
      return addr
    }
  },
  methods: {
    provinceChange(e) {
      const i = e.target.selectedIndex
      this.$emit('setCity', { province: e.target.value, address: this.address, index: i })
      this.$emit('update:province', e.target.value)
    },
    cityChange(e) {
      const i = e.target.selectedIndex
      this.$emit('setCounty', { city: e.target.value, address: this.address, index: i })
      this.$emit('update:city', e.target.value)
    },
    districtChange(e) {
      this.$emit('update:district', e.target.value)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
label {
  display: inline-block;
  margin: 10px auto;

  span {
    display: inline-block;
    width: 100px;
    vertical-align: middle;
    margin-right: 10px;
  }

  input {
    width: 200px;
    height: 25px;
    border: none;
    background: #fff;
    font-size: 16px;
  }

  .input_url {
    width: 500px;
  }

  em {
    margin-left: 5px;
  }

}

input[type="submit"] {
  width: 200px;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  background: #0092ff;
  color: #fff;
  border: none;
  border-radius: 5px;
}

div select {
  width: 200px;
}
</style>
