<template>
  <div class="form">
    <form>
      <div>
        <label>
          <span>题型</span>
          <select :value="type" @change="$emit('update:type', $event.target.value)">
            <option name="type" value="马哲">马哲</option>
            <option name="type" value="毛概">毛概</option>
            <option name="type" value="申论">申论</option>
            <option name="type" value="行测">行测</option>
          </select>
        </label>
        <label>
          <span>标题</span>
          <textarea rows="2" cols="30" :value="title" @input="$emit('update:title', $event.target.value)"></textarea>
          <!-- <em @click="$emit('clearTitle', '')">X</em> -->
        </label>
        <label>
          <span>内容</span>
          <textarea rows="3" cols="35" :value="content" @input="$emit('update:content', $event.target.value)"></textarea>
        </label>
      </div>
      <div>
        <input type="submit" value="提交" @click.prevent="$emit('add')">
      </div>
    </form>
  </div>
</template>

<script>
// import addr from '@/util/address.js'
export default {
  name: 'FormAdd',
  props: {
    type: String,
    title: String,
    content: String
  },
  emits: ['update:type', 'update:title', 'update:contents', 'add'],
  computed: {

  },
  methods: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
label {
  display: inline-block;
  margin: 5px auto;

  span {
    display: inline-block;
    width: 120px;
    vertical-align: middle;
    margin-right: 10px;
  }

  select {
    width: 80px;
  }

  input {
    width: 200px;
    height: 25px;
    border: none;
    background: #fff;
    font-size: 16px;
  }

  textarea {
    vertical-align: middle;
  }

  .input_url {
    width: 500px;
  }

  em {
    margin-left: 5px;
  }

}

input[type="submit"] {
  width: 200px;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  background: #0092ff;
  color: #fff;
  border: none;
  border-radius: 5px;
}

// div select {
//   width: 200px;
// }
</style>
