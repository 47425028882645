import request from '@/util/request.js'

export function add(params) {
  return request({
    url: '/admin/officer_selector',
    method: 'post',
    data: params,
    transformRequest: [data => {
      const formData = new FormData()
      for (const key in params) {
        formData.append(key, params[key])
      }
      return formData
    }]
  })
}

export function update(params, id) {
  return request({
    url: '/admin/officer_selector/' + id,
    method: 'put',
    params: params,
    transformRequest: [data => {
      const formData = new FormData()
      for (const key in params) {
        formData.append(key, params[key])
      }
      return formData
    }]
  })
}

export function list(params) {
  return request({
    url: '/admin/officer_selector',
    method: 'get',
    params: params
  })
}

export function deleteItem(params) {
  return request({
    url: '/admin/officer_selector/' + params,
    method: 'delete',
    params: params
  })
}
