const address = [
  {
    id: 0,
    province: '贵州省',
    city: [
      {
        id: 'guiyang',
        district: '贵阳市',
        county: ['贵阳市', '观山湖区', '南明区', '云岩区', '花溪区', '乌当区', '白云区', '清镇市', '开阳县', '息烽县', '修文县']
      },
      {
        id: 'zunyi',
        district: '遵义市',
        county: ['遵义市', '汇川区', '红花岗区', '播州区', '赤水市', '仁怀市', '桐梓县', '绥阳县', '正安县', '道真仡佬族苗族自治县', '务川仡佬族苗族自治县 ', '凤冈县', '湄潭县', '余庆县', '习水县']
      },
      {
        id: 'liupanshui',
        district: '六盘水市',
        county: ['六盘水市', '钟山区', '水城区', '盘州市', '六枝特区']
      },
      {
        id: 'anshun',
        district: '安顺市',
        county: ['安顺市', '西秀区', '平坝区', '镇宁布依族苗族自治县', '普定县', '关岭布依族苗族自治县', '紫云苗族布依族自治县']
      },
      {
        id: 'bijie',
        district: '毕节市',
        county: ['毕节市', '七星关区', '黔西市', '赫章县', '威宁彝族回族苗族自治县', '纳雍县', '织金县', '金沙县', '大方县']
      },
      {
        id: 'tongren',
        district: '铜仁市',
        county: ['铜仁市', '碧江区', '万山区', '江口县', '玉屏侗族自治县', '石阡县', '思南县', '印江土家族苗族自治县', '德江县', '沿河土家族自治县', '松桃苗族自治县']
      },
      {
        id: 'qiandongnan',
        district: '黔东南苗族侗族自治州',
        county: ['黔东南苗族侗族自治州', '凯里市', '镇远县', '黄平县', '施秉县', '三穗县', '岑巩县', '天柱县', '锦屏县', '剑河县', '台江县 ', '黎平县', '榕江县', '从江县', '雷山县', '麻江县', '丹寨县']
      },
      {
        id: 'qiannan',
        district: '黔南布依族苗族自治州',
        county: ['黔南布依族苗族自治州', '都匀市', '福泉市', '荔波县', '贵定县', '瓮安县', '独山县', '平塘县', '罗甸县', '长顺县', '龙里县 ', '惠水县', '三都水族自治县']
      },
      {
        id: 'qianxinan',
        district: '黔西南布依族苗族自治州',
        county: ['黔西南布依族苗族自治州', '兴义市', '普安县', '晴隆县', '贞丰县', '望谟县', '册亨县', '安龙县']
      }
    ]
  },
  {
    id: 1,
    province: '重庆',
    city: [
      {
        id: 'chongqing',
        district: '重庆市',
        county: ['重庆市', '万州区', '涪陵区', '渝中区', '大渡口区', '江北区', '沙坪坝区', '南岸区', '北培区', '綦江区', '大足区', '渝北区', '巴南区', '黔江区', '长寿区', '江津区', '合川区', '永川区', '南川区', '璧山区', '铜梁区', '潼南区', '荣昌区', '开州区', '梁平区', '武隆区', '九龙坡区', '城口县', '丰都县', '垫江县', '忠县', '云阳县', '奉节县', '巫山县', '巫溪县', '石柱土家族自治县', '秀山土家族苗族自治县', '酉阳土家族苗族自治县', '彭水苗族土家族自治县']
      }
    ]
  },
  {
    id: 1,
    province: '湖南省',
    city: [
      {
        id: 'changsha',
        district: '长沙市',
        county: ['芙蓉区', '天心区', '岳麓区', '开福区', '雨花区', '望城区', '宁乡市', '浏阳市', '长沙县']
      },
      {
        id: 'zhuzhou',
        district: '株洲市',
        county: ['天元区', '芦淞区', '荷塘区', '石峰区', '渌口区', '醴陵市', '攸县', '茶陵县', '炎陵县']
      },
      {
        id: 'xiangtan',
        district: '湘潭市',
        county: ['雨湖区', '岳塘区', '湘乡市', '韶山市', '湘潭县']
      },
      {
        id: 'hengyang',
        district: '衡阳市',
        county: ['雁峰区', '石鼓区', '珠晖区', '蒸湘区', '南岳区', '耒阳市', '常宁市', '衡阳县', '衡南县', '衡山县', '衡东县', '祁东县']
      },
      {
        id: 'shaoyang',
        district: '邵阳市',
        county: ['双清区', '大祥区', '北塔区', '武冈市', '邵东市', '新邵县', '邵阳县', '隆回县', '洞口县', '绥宁县', '新宁县', '城步苗族自治县']
      },
      {
        id: 'yueyang',
        district: '岳阳市',
        county: ['岳阳楼区', '云溪区', '君山区', '汨罗市', '临湘市', '湘阴县', '岳阳县', '华容县', '平江县']
      },
      {
        id: 'changde',
        district: '常德市',
        county: ['武陵区', '鼎城区', '津市市', '安乡县', '汉寿县', '桃源县', '临澧县', '石门县', '澧县']
      },
      {
        id: 'zhangjiajie',
        district: '张家界市',
        county: ['永定区', '武陵源区', '慈利县', '桑植县']
      },
      {
        id: 'yiyang',
        district: '益阳市',
        county: ['资阳区', '赫山区', '沅江市', '安化县', '桃江县', '南县', '大通湖管理区']
      },
      {
        id: 'loudi',
        district: '娄底市',
        county: ['娄星区', '冷水江市', '涟源市', '双峰县', '新化县']
      },
      {
        id: 'chenzhou',
        district: '郴州市',
        county: ['北湖区', '苏仙区', '资兴市', '桂阳县', '宜章县', '永兴县', '嘉禾县', '临武县', '汝城县', '桂东县', '安仁县']
      },
      {
        id: 'yongzhou',
        district: '永州市',
        county: ['零陵区', '冷水滩区', '祁阳市  ', '双牌县', '东安县', '道县', '宁远县', '新田县', '蓝山县', '江永县', '江华瑶族自治县']
      },
      {
        id: 'huaihua',
        district: '怀化市',
        county: ['鹤城区', '洪江管理区', '洪江市', '中方县', '沅陵县', '辰溪县', '溆浦县', '会同县', '麻阳苗族自治县', '新晃侗族自治县', '芷江侗族自治县', '靖州苗族侗族自治县', '通道侗族自治县']
      },
      {
        id: 'xiangxi',
        district: '湘西土家族苗族自治州',
        county: ['吉首市', '泸溪县', '凤凰县', '花垣县', '保靖县', '古丈县', '永顺县', '龙山县']
      }
    ]
  }
]

export default address
