<template>
  <div class="addNote">
    <!-- <QuestionForm v-model:type="addParams.type" v-model:title="addParams.title" v-model:score="addParams.score" v-model:answer="addParams.answer" v-model:analysis="addParams.analysis" v-model:contents="contents" @add="submit" /> -->
    <NoteForm v-model:type="addParams.type" v-model:title="addParams.title" v-model:content="addParams.content" @add="submit" />
    <div class="list">
      <div>
        <span>题型</span>
        <select v-model="select.type" @change="typeChange">
          <option name="type" value="马哲">马哲</option>
          <option name="type" value="毛概">毛概</option>
          <option name="type" value="申论">申论</option>
          <option name="type" value="行测">行测</option>
        </select>
      </div>
      <table>
        <tr>
          <th>id</th>
          <th>题型</th>
          <th>题目</th>
          <th>内容</th>
          <th>操作</th>
        </tr>
        <tr v-for="item in listData" :key="item.id">
          <td>{{item.id}}</td>
          <td>{{item.type}}</td>
          <td>
            <p>{{item.title}}</p>
          </td>
          <td>
            <p>{{item.content}}</p>
          </td>
          <td>
            <button @click="edit(item)">修改</button>
            <button class="del" @click="deleteForm(item.id)">删除</button>
          </td>
        </tr>
      </table>
      <!-- 分页 -->
      <!-- <ul>
        <li v-for="p in totalPage" :key="p" @click="changePage(p)" :class="{ active: p === select.page }">
          <span>{{p}}</span>
        </li>
      </ul> -->
      <pagenation
        :totalPage="total"
        :current="current"
        :panelNumber="10"
        @changePage="changePage"
        @previous="previous"
        @next="next"/>
    </div>
    <modal :show="modalShow" @close="modalShow=false">
      <!-- <QuestionForm
        v-model:type="editParams.type"
        v-model:title="editParams.title"
        v-model:score="editParams.score"
        v-model:answer="editParams.answer"
        v-model:analysis="editParams.analysis"
        v-model:contents="editContents"
        @add="editSubmit" /> -->
        <NoteForm v-model:type="editParams.type" v-model:title="editParams.title" v-model:content="editParams.content" @add="editSubmit" />
    </modal>
  </div>
</template>
<script>
// import axios from 'axios'
import { add, list, update, deleteItem } from '@/api/note.js'
import NoteForm from '@/components/NoteForm.vue'
import modal from '@/components/Modal.vue'
import pagenation from '@/components/Pagenation.vue'
export default {
  components: {
    NoteForm,
    modal,
    pagenation
  },
  data() {
    return {
      addParams: {
        type: '马哲',
        title: '',
        content: ''
      },
      editParams: {
        type: '',
        title: '',
        content: ''
      },
      modalShow: false,
      id: '',

      select: {
        type: '',
        title: '',
        page: 1
      },
      listData: [],
      // totalPage: 0,
      data: null,
      total: 0,
      current: 1
    }
  },
  computed: {

  },
  created() {
    this.requestList(this.select)
  },
  methods: {
    submit() {
      // console.log(this.addContent, this.addParams)
      add(this.addParams).then(res => {
        if (res.status === 200) {
          this.select.page = 1
          this.requestList(this.select)
        }
      })
    },

    typeChange(e) {
      this.select.type = e.target.value
      this.select.page = 1
      this.requestList(this.select)
    },

    // 请求list
    requestList(params) {
      list(params).then(res => {
        if (res.status === 200) {
          this.listData = res.data.data
          this.total = res.data.meta.pagination.total_pages
        }
      }, err => {
        console.log(err)
      })
    },
    // changePage(currentPage) {
    //   this.select.page = currentPage
    //   this.requestList(this.select)
    // },
    changePage(p) {
      this.current = p
      this.select.page = p
      this.requestList(this.select)
    },
    previous(p) {
      this.current = p
      this.select.page = p
      this.requestList(this.select)
    },
    next(p) {
      this.current = p
      this.select.page = p
      this.requestList(this.select)
    },

    edit(data) {
      this.modalShow = true
      this.editParams = {
        type: data.type,
        title: data.title,
        content: data.content
      }
      this.id = data.id
    },
    // 修改
    editSubmit() {
      update(this.editParams, this.id).then(res => {
        if (res.status === 200) {
          this.requestList(this.select)
          this.modalShow = false
        }
      })
    },
    deleteForm(id) {
      if (confirm('您确定要删除吗')) {
        deleteItem(id).then(res => {
          if (res.status === 200) {
            this.requestList(this.select)
          }
        })
      } else {
        return false
      }
    }
  }
}

</script>
<style scoped lang="less">
.addNote {
  .list {
    width: 100%;
    margin: 20px auto;

    div {
      width: 100%;
      margin: 10px auto;
    }

    table {
      width: 100%;

      td {
        overflow-x: auto;
        overflow: hidden;
        max-width: 500px;

        button {
          border: none;
          background: green;
          color: #fff;
        }

        .del {
          background: red;
        }

        p {
          width: 100%;
          height: 50px;
          overflow: auto;
        }
      }
    }

    ul {
      width: 100%;
      list-style: none;

      li {
        display: inline-block;
        margin: 10px 5px;
        background: #006fff;

        span {
          padding: 5px 10px;
          color: #fff;
        }
      }

      .active {
        background: #00c1ff;
      }
    }
  }
}

</style>
