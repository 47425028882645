<template>
  <div class="mianjing">
    <h1>this is mianjing manage</h1>
    <table>
      <tr>
        <th>id</th>
        <th>问题</th>
        <th>描述</th>
        <th>回答数量</th>
        <th>审核状态</th>
        <th>操作</th>
      </tr>
      <tr v-for="item in data" :key="item.id">
        <td>{{item.id}}</td>
        <td>{{item.title}}</td>
        <td>{{item.description}}</td>
        <td>{{item.answer.length}}</td>
        <td v-if="item.status===0">未审核</td>
        <td v-else-if="item.status===1">通过</td>
        <td v-else>不通过</td>
        <td>
          <select @change="check(item.id, $event)">
            <option value="0"></option>
            <option value="2">不通过</option>
            <option value="1">通过</option>
          </select>
          <a class="del" @click="del(item.id)">删除</a>
        </td>
      </tr>
    </table>
    <Pagenation
    :totalPage="total"
    :current="current"
    :panelNumber="10"
    @changePage="changePage"
    @previous="previous"
    @next="next" />
  </div>
</template>

<script>
// @ is an alias to /src
import Pagenation from '@/components/Pagenation.vue'
import { listQ, checkQ, deleteQ } from '@/api/mianjing.js'

export default {
  name: 'Mianjing',
  components: {
    Pagenation
  },
  created() {
    this.requestList(this.params)
  },
  data() {
    return {
      total: 1,
      current: 1,
      params: {
        page: 1
      },
      data: []
    }
  },
  methods: {
    check(id, e) {
      checkQ({ id: id, status: e.target.value }).then(res => {
        this.requestList(this.params)
      })
    },
    del(id) {
      if (confirm('您确定要删除吗')) {
        deleteQ({ id: id }).then(res => {
          // console.log(res.status)
          if (res.status === 200) {
            this.requestList(this.params)
          }
        })
      } else {
        return false
      }
    },
    changePage(p) {
      this.current = p
      this.params.page = p
      this.requestList(this.params)
    },
    previous(p) {
      this.current = p
      this.params.page = p
      this.requestList(this.params)
    },
    next(p) {
      this.current = p
      this.params.page = p
      this.requestList(this.params)
    },
    requestList(params) {
      listQ(params).then(res => {
        this.total = res.data.meta.pagination.total_pages
        this.data = res.data.data
      })
    }
  }
}
</script>

<style lang="less" scoped>
.mianjing {
  table {
    width: 100%;
  }
  tr {
    height: 50px;
    td {
      max-width: 300px;
      button {
        border: none;
        background: green;
        color: #fff;
      }
      .del {
        margin-left: 5px;
        padding: 2px 10px;
        background-color: #be4027;
        color: #fff;
        border-radius: 5px;
      }
      select {
        width: 100px;
      }
    }
  }
}
</style>
