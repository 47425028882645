import request from '@/util/request.js'
// import qs from 'qs'

export function listQ(params) {
  return request({
    url: '/admin/question',
    method: 'get',
    params: params
  })
}

export function listAnser(params) {
  return request({
    url: '/admin/answer',
    method: 'get',
    params: params
  })
}

export function checkQ(params) {
  return request({
    url: '/admin/question/check',
    method: 'post',
    data: params
  })
}

export function checkAnser(params) {
  return request({
    url: '/admin/answer/check',
    method: 'post',
    data: params
  })
}

export function deleteQ(params) {
  return request({
    url: '/admin/question/' + params.id,
    method: 'DELETE'
  })
}

export function deleteAnser(params) {
  return request({
    url: '/admin/answer/' + params.id,
    method: 'DELETE'
  })
}
