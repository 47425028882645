<template>
  <div class="add">
    <FormAdd
      v-model:province="form.province"
      v-model:city="form.city"
      v-model:district="form.district"
      v-model:list_url="form.list_url"
      v-model:list_selector="form.list_selector"
      v-model:pub_date_selector="form.pub_date_selector"
      v-model:title_selector="form.title_selector"
      v-model:content_selector="form.content_selector"
      v-model:attachment_selector="form.attachment_selector" v-model:source="form.source"
      :citys="citys"
      :countys="countys"
      @add="submit"
      @setCity="setCity"
      @setCounty="setCounty"
      @clearUrl="form.list_url=$event" />
    <div class="list">
      <div>
        <span>省:</span>
        <select v-model.trim="select.province" @change="selectPChange" placeholder="请选择省/市">
          <option name="city" v-for="(item, index) in address" :key="item.id" :value="item.province" :i="index">{{item.province}}</option>
        </select>
        <span>市:</span>
        <select v-model.trim="select.city" @change="selectCityChange" placeholder="请选择市">
          <option name="city" v-for="(item, index) in selectCity" :key="item.id" :value="item.district" :i="index">{{item.district}}</option>
        </select>
        <span>县／区:</span>
        <select v-model="select.district" @change="selectCounty">
          <option v-for="i in selectCountys" :key="i" :value="i">{{i}}</option>
        </select>
        <span>list url:</span>
        <input type="text" name="list_url" v-model.trim="select.list_url" placeholder="请填写list的url" @change="selectUrl">
        <em @click="clearSelectUrl">X</em>
      </div>
      <table>
        <tr>
          <th>id</th>
          <th>市</th>
          <th>县／区</th>
          <th>list_url</th>
          <th>list_selector</th>
          <th>content_selector</th>
          <th>attachment_selector</th>
          <th>title_selector</th>
          <!-- <th>source</th> -->
          <th>操作</th>
        </tr>
        <tr v-for="item in listData" :key="item.id">
          <td>{{item.id}}</td>
          <td>{{item.city}}</td>
          <td>{{item.district}}</td>
          <td>
            <a :href="item.list_url" target="_blank">{{item.list_url}}</a>
          </td>
          <td>{{item.list_selector}}</td>
          <td>{{item.content_selector}}</td>
          <td>{{item.attachment_selector}}</td>
          <td>{{item.title_selector}}</td>
          <!-- <td>{{item.source}}</td> -->
          <td>
            <button @click="edit(item)">修改</button>
            <button class="del" @click="deleteForm(item.id)">删除</button>
          </td>
        </tr>
      </table>
      <!-- 分页 -->
      <ul>
        <li v-for="p in totalPage" :key="p" @click="changePage(p)" :class="{ active: p === select.page }">
          <span>{{p}}</span>
        </li>
      </ul>
    </div>
    <Modal :show="modalShow" @close="modalShow=false">
      <FormAdd
        v-model:province="updateForm.province"
        v-model:city="updateForm.city"
        v-model:district="updateForm.district"
        v-model:list_url="updateForm.list_url"
        v-model:list_selector="updateForm.list_selector"
        v-model:pub_date_selector="updateForm.pub_date_selector" v-model:title_selector="updateForm.title_selector" v-model:content_selector="updateForm.content_selector" v-model:attachment_selector="updateForm.attachment_selector" v-model:source="updateForm.source"
        :citys="updateCitys"
        :countys="updateCountys"
        @add="submitForm"
        @setCity="updateCity"
        @setCounty="updateCounty"
        @clearUrl="updateForm.list_url=$event" />
    </Modal>
  </div>
</template>
<script>
// import axios from 'axios'
import { add, list, update, deleteItem } from '@/api/add.js'
import addr from '@/util/address.js'
import FormAdd from '@/components/FormAdd.vue'
import Modal from '@/components/Modal.vue'
export default {
  components: {
    FormAdd,
    Modal
  },
  data() {
    return {
      form: {
        province: '贵州省',
        city: '',
        district: '',
        list_url: '',
        list_selector: '',
        pub_date_selector: '',
        title_selector: '',
        // department_selector: '',
        content_selector: '',
        attachment_selector: '',
        source: ''
      },
      updateForm: {
        province: '',
        city: '111',
        district: '222',
        list_url: '',
        list_selector: '',
        pub_date_selector: '',
        title_selector: '',
        // department_selector: '',
        content_selector: '',
        attachment_selector: '',
        source: ''
      },
      updateCitys: [],
      updateCountys: [],
      updateProvinceIndex: 0,
      modalShow: false,
      id: '',
      countys: [],
      citys: addr[0].city,
      provinceIndex: 0,
      select: {
        province: '',
        city: '',
        district: '',
        list_url: '',
        page: 1
      },
      selectCity: [],
      selectCountys: [],
      listData: [],
      totalPage: 0,
      data: null
    }
  },
  computed: {
    address() {
      // console.log(addr)
      return addr
    }
  },
  created() {
    this.requestList(this.select)
  },
  methods: {
    /** *********** 添加搜索引擎 start **************** **/
    submit(event) {
      // event.preventDefault()
      add(this.form).then(res => {
        console.log(res.data)
        if (res.status === 200) {
          this.select.page = 1
          this.requestList(this.select)
        }
      })
    },
    setCity(e) {
      const address = e.address
      this.provinceIndex = e.index
      this.citys = address[this.provinceIndex].city
    },
    setCounty(e) {
      const address = e.address
      const i = e.index
      this.countys = address[this.provinceIndex].city[i].county
    },
    /** *********** 添加搜索引擎 end **************** **/

    /** *********** 搜索栏 start **************** **/
    selectPChange(e) {
      this.address.filter((item, index) => {
        if (item.province === e.target.value) {
          this.selectCity = item.city
          return index
        }
      })
      this.select.province = e.target.value
      this.select.city = ''
      this.select.district = ''
      this.select.page = 1
      this.requestList(this.select)
    },
    selectCityChange(e) {
      this.selectCity.filter((item, index) => {
        if (item.district === e.target.value) {
          this.selectCountys = item.county
          return index
        }
      })
      this.select.city = e.target.value
      this.select.district = ''
      this.select.page = 1
      this.requestList(this.select)
    },
    selectCounty(e) {
      this.select.district = e.target.value
      this.select.page = 1
      this.requestList(this.select)
    },
    selectUrl(e) {
      this.select.province = ''
      this.select.city = ''
      this.select.district = ''
      this.select.page = 1
      this.requestList(this.select)
    },
    clearSelectUrl() {
      this.select.list_url = ''
    },
    /** *********** 搜索栏 end **************** **/
    // 请求list
    requestList(params) {
      list(params).then(res => {
        if (res.status === 200) {
          this.listData = res.data.data
          this.totalPage = res.data.meta.pagination.total_pages
        }
      }, err => {
        console.log(err)
      })
    },
    changePage(currentPage) {
      this.select.page = currentPage
      this.requestList(this.select)
    },
    /** *********** 修改搜索引擎 start **************** **/
    updateCity(e) {
      const address = e.address
      this.updateProvinceIndex = e.index
      this.updateCitys = address[this.updateProvinceIndex].city
      this.updateCountys = []
    },
    updateCounty(e) {
      const address = e.address
      const i = e.index
      this.updateCountys = address[this.updateProvinceIndex].city[i].county
      // const address = e.address
      // const value = e.city
      // address.filter((item, index) => {
      //   if (item.district === value) {
      //     this.updateCountys = item.county
      //     return index
      //   }
      // })
    },
    edit(data) {
      this.modalShow = true
      console.log(data)
      this.updateForm = {
        province: data.province,
        city: data.city,
        district: data.district,
        list_url: data.list_url,
        list_selector: data.list_selector,
        pub_date_selector: data.pub_date_selector,
        title_selector: data.title_selector,
        // department_selector: '',
        content_selector: data.content_selector,
        attachment_selector: data.attachment_selector,
        source: data.source
      }
      this.id = data.id
      // 某一个省的城市
      const addrIndex = []
      addr.filter((item, index) => {
        if (item.province === data.province) {
          this.updateCitys = item.city
          addrIndex[0] = index
          // this.updateCountys = item.county
          return addrIndex
        }
      })
      addr[addrIndex[0]].city.filter((item, index) => {
        if (item.district === data.city) {
          this.updateCountys = item.county
          return index
        }
      })
    },
    // 修改
    submitForm() {
      update(this.updateForm, this.id).then(res => {
        console.log(res.data)
        if (res.status === 200) {
          this.requestList(this.select)
          this.modalShow = false
        }
      })
    },
    /** *********** 修改搜索引擎 end **************** **/
    deleteForm(id) {
      if (confirm('您确定要删除吗')) {
        deleteItem(id).then(res => {
          if (res.status === 200) {
            this.requestList(this.select)
          }
        })
      } else {
        return false
      }
    }
  }
}

</script>
<style scoped lang="less">
.add {
  .list {
    width: 100%;
    margin: 20px auto;

    div {
      width: 100%;
      margin: 10px auto;
    }

    table {
      width: 100;

      td {
        max-width: 180px;
        overflow-x: auto;

        button {
          border: none;
          background: green;
          color: #fff;
        }

        .del {
          background: red;
        }
      }
    }

    ul {
      width: 100%;
      list-style: none;

      li {
        display: inline-block;
        margin: 10px 5px;
        background: #006fff;

        span {
          padding: 5px 10px;
          color: #fff;
        }
      }

      .active {
        background: #00c1ff;
      }
    }
  }
}

</style>
