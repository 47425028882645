<template>
    <div class="modal" v-if="show" @click.self="$emit('close')">
      <div class="shadow">
        <span class="cancle" @click.self="$emit('close')">取消</span>
        <slot></slot>
      </div>
    </div>
</template>
<script>
export default {
  name: 'modal',
  props: {
    show: Boolean
  },
  emits: ['close'],
  computed: {

  }
}

</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  margin: auto;
  z-index: 1000;
  top: 0;
  left: 0;
  background: rgba(214, 214, 214, 0.8);

  .shadow {
    position: absolute;
    width: 80%;
    height: 80%;
    margin: auto;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #ccc;

    .form {
      margin: 60px auto;
    }

    .cancle {
      float: right;
      margin: 5px 5px 0;
    }
  }
}

</style>
