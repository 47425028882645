import request from '@/util/request.js'

export function getData(params) {
  return request({
    url: '/admin/officer',
    method: 'GET',
    params: params
  })
}

export function add(params) {
  return request({
    url: '/admin/officer/' + params.id,
    method: 'PUT',
    data: params
  })
}

export function deleteClass(params) {
  return request({
    url: '/admin/officer/' + params.id,
    method: 'DELETE'
  })
}