<template>
  <div class="form">
    <form>
      <div>
        <label>
          <span>题型</span>
          <select :value="type" @change="$emit('update:type', $event.target.value)">
            <option name="type" value="single">单选题</option>
            <option name="type" value="multi">多选题</option>
            <option name="type" value="ask">问答题</option>
            <option name="type" value="judge">判断题</option>
            <option name="type" value="写作">写作</option>
          </select>
        </label>
        <label>
          <span>题目</span>
          <textarea rows="3" cols="35" :value="title" @input="$emit('update:title', $event.target.value)"></textarea>
          <!-- <em @click="$emit('clearTitle', '')">X</em> -->
        </label>
        <label v-if="type==='single' || type==='multi' || type==='judge'">
          <span>如果是选择题或判断题</span>
          <textarea rows="3" cols="35" :value="contents" @input="$emit('update:contents', $event.target.value)"></textarea>
        </label>
      </div>
      <div>
        <label>
          <span>分值</span>
          <input type="text" name="" :value="score" @input="$emit('update:score', $event.target.value)" >
        </label>
        <label>
          <span>答案</span>
          <input type="text" name="" v-if="type==='single' || type==='multi' || type==='judge'" :value="answer" @input="$emit('update:answer', $event.target.value)">
          <textarea v-if="type==='ask' || type==='写作'" :value="analysis" @input="$emit('update:analysis', $event.target.value)"></textarea>
        </label>

        <label>
          <span>解析</span>
          <textarea rows="3" cols="30" :value="analysis" @input="$emit('update:analysis', $event.target.value)"></textarea>
        </label>
      </div>
      <div>
        <input type="submit" value="提交" @click.prevent="$emit('add')">
      </div>
    </form>
  </div>
</template>

<script>
// import addr from '@/util/address.js'
export default {
  name: 'FormAdd',
  props: {
    type: String,
    title: String,
    contents: String,
    answer: String,
    score: String,
    analysis: String
  },
  emits: ['update:type', 'update:title', 'update:contents', 'update:answer', 'update:score', 'update:analysis', 'add'],
  computed: {

  },
  methods: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
label {
  display: inline-block;
  margin: 5px auto;

  span {
    display: inline-block;
    width: 120px;
    vertical-align: middle;
    margin-right: 10px;
  }

  select {
    width: 80px;
  }

  input {
    width: 200px;
    height: 25px;
    border: none;
    background: #fff;
    font-size: 16px;
  }

  textarea {
    vertical-align: middle;
  }

  .input_url {
    width: 500px;
  }

  em {
    margin-left: 5px;
  }

}

input[type="submit"] {
  width: 200px;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  background: #0092ff;
  color: #fff;
  border: none;
  border-radius: 5px;
}

// div select {
//   width: 200px;
// }
</style>
