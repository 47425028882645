<template>
  <div class="edit">
    <h1>This is a page about recruitment information classification</h1>
    <div>
      <span>省:</span>
      <select v-model.trim="select.province" @change="selectPChange" placeholder="请选择省">
        <option name="city" v-for="(item, index) in address" :key="item.id" :value="item.province" :i="index">{{item.province}}</option>
      </select>
      <span>市:</span>
      <select v-model.trim="select.city" @change="selectCityChange" placeholder="请选择市">
        <option name="city" v-for="(item, index) in selectCitys" :key="item.id" :value="item.district" :i="index">{{item.district}}</option>
      </select>
      <span>县／区:</span>
      <select v-model="select.district" @change="selectCounty">
        <option v-for="i in selectCountys" :key="i" :value="i">{{i}}</option>
      </select>
      <span>是否已经添加</span>
      <select v-model="select.status">
        <option>全部</option>
        <option>未添加</option>
        <option>已经添加</option>
      </select>
    </div>
    <table>
      <tr>
        <th>id</th>
        <th>标题</th>
        <th>编制类型</th>
        <th>招考类型</th>
        <th>日期</th>
        <th>操作</th>
      </tr>
      <tr v-for="item in listData" :key="item.id">
        <td>{{item.id}}</td>
        <td>
          <a :href="item.source_url" target="_blanck">{{item.title}}</a>
        </td>
        <td>
          <select @change="updateJob(item.id, $event)">
            <option></option>
            <option>事业单位</option>
            <option>公务员</option>
            <option>教师</option>
            <option>医院</option>
            <option>定向医护</option>
            <option>警察</option>
            <option>消防员</option>
            <option>村务员</option>
            <option>看护员&协管员</option>
            <option>公益岗位</option>
            <option>人才引进</option>
            <option>其他</option>
          </select>
          <span>{{item.job_type}}</span>
        </td>
        <td>
          <select @change="updateArticle(item.id, $event)">
            <option></option>
            <option>招聘</option>
            <option>初审/复审/政审/考察</option>
            <option>笔试</option>
            <option>面试</option>
            <option>体检</option>
            <option>成绩</option>
            <option>考调</option>
            <option>聘用</option>
            <option>其他</option>
          </select>
          <span>{{item.article_type}}</span>
        </td>
        <td>{{item.pub_date}}</td>
        <td>
          <!-- <button @click="add(item.id)">修改</button> -->
          <button class="del" @click="del(item.id)">删除</button>
        </td>
      </tr>
    </table>
    <pagenation
    :totalPage="total"
    :current="current"
    :panelNumber="10"
    @changePage="changePage"
    @previous="previous"
    @next="next"/>
  </div>
</template>
<script type="text/javascript">
import { getData, add, deleteClass } from '@/api/information.js'
import addr from '@/util/address.js'
import pagenation from '@/components/Pagenation.vue'

export default {
  created() {
    this.requestList(this.select)
  },
  components: {
    pagenation
  },
  data() {
    return {
      types: {
        id: '',
        article_type: '',
        job_type: ''
      },
      select: {
        province: '贵州省',
        city: '',
        district: '',
        page: 1,
        status: 0
      },
      selectCitys: addr[0].city,
      selectCountys: [],
      listData: null,
      total: 0,
      current: 1
    }
  },
  computed: {
    address() {
      // console.log(addr)
      return addr
    }
  },
  methods: {
    selectPChange(e) {
      this.address.filter((item, index) => {
        if (item.province === e.target.value) {
          this.selectCitys = item.city
          return index
        }
      })
      this.select.province = e.target.value
      this.select.district = ''
      this.select.page = 1
      this.current = 1
      this.requestList(this.select)
    },
    // setCounty(e) {
    //   // console.log(e)
    //   const address = e.address
    //   const value = e.city
    //   address.filter((item, index) => {
    //     if (item.district === value) {
    //       this.countys = item.county
    //       return index
    //     }
    //   })
    // },
    selectCityChange(e) {
      this.selectCitys.filter((item, index) => {
        if (item.district === e.target.value) {
          this.selectCountys = item.county
          return index
        }
      })
      this.select.city = e.target.value
      this.select.district = ''
      this.select.page = 1
      this.current = 1
      this.requestList(this.select)
    },
    selectCounty(e) {
      this.select.district = e.target.value
      this.select.page = 1
      this.current = 1
      this.requestList(this.select)
    },
    requestList(params) {
      getData(params).then(res => {
        if (res.status === 200) {
          this.listData = res.data.data
          // console.log(res.data)
          this.total = res.data.meta.pagination.total_pages
        }
      }, err => {
        console.log(err)
      })
    },
    updateJob(id, e) {
      // console.log(id, e.target.value)
      this.types.id = id
      this.types.job_type = e.target.value
      add(this.types).then(res => {
        if (res.status === 200) {
          this.requestList(this.select)
          this.types.job_type = ''
        }
      })
    },
    updateArticle(id, e) {
      // console.log(id, e.target.value)
      this.types.id = id
      this.types.article_type = e.target.value
      add(this.types).then(res => {
        if (res.status === 200) {
          this.requestList(this.select)
          this.types.article_type = ''
        }
      })
    },
    del(id) {
      if (confirm('您确定要删除吗')) {
        deleteClass({ id }).then(res => {
          if (res.status === 200) {
            this.requestList(this.select)
          }
        })
      } else {
        return false
      }
    },
    changePage(p) {
      this.current = p
      this.select.page = p
      this.requestList(this.select)
    },
    previous(p) {
      this.current = p
      this.select.page = p
      this.requestList(this.select)
    },
    next(p) {
      this.current = p
      this.select.page = p
      this.requestList(this.select)
    }
  }
}

</script>

<style lang="less" scoped>
.edit {
  tr {
    height: 50px;
    td {
      max-width: 500px;
      button {
        border: none;
        background: green;
        color: #fff;
      }
      .del {
        background: red;
      }
      select {
        width: 100px;
      }
    }
  }
}
</style>
