<template>
  <div class="pager">
    <ul>
      <li @click="$emit('previous', current - 1)"><span>上一页</span></li>
      <li v-for="p in pagers" :key="p" @click="$emit('changePage', p)" :class="{ active: p === current }">
        <span>{{p}}</span>
      </li>
      <li @click="$emit('next', current + 1)"><span>下一页</span></li>
      <li><span>共{{totalPage}}页</span></li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'pager',
  props: {
    totalPage: Number,
    current: Number,
    panelNumber: Number
  },
  emits: ['changePage', 'previous', 'next'],
  computed: {
    pagers() {
      let pagers = [],
          min,
          max
      if (this.totalPage !== 0 && (this.current < 1 || this.current > this.totalPage)) {
        return
      }
      min = this.current - Math.floor(this.panelNumber / 2)
      if (min < 1) {
        min = 1
      }
      max = min + this.panelNumber - 1
      if (max > this.totalPage) {
        max = this.totalPage
      }
      min = max -this.panelNumber + 1
      if (min < 1) {
        min = 1
      }
      for (let i = min; i <= max; i++) {
          pagers.push(i);
      }
      return pagers
    }
  }
}

</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.pager {
  width: 100%;

  ul {
    width: 100%;
    list-style: none;

    li {
      display: inline-block;
      margin: 10px 5px;
      background: #006fff;

      span {
        padding: 5px 10px;
        color: #fff;
      }
    }

    .active {
      background: #00c1ff;
    }
  }
}

</style>
