<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo_icon.png" height="360" width="380">
    <HelloWorld msg="Welcome to 哒哒马公考后台管理系统"/>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld
  }
}
</script>
