import request from '@/util/request.js'
import qs from 'qs'

export function add(params) {
  return request({
    url: '/admin/note',
    method: 'post',
    data: params,
    paramsSerializer: params => {
      return qs.stringify(params, { indices: false })
    }
    // transformRequest: [data => {
    //   const formData = new FormData()
    //   for (const key in params) {
    //     formData.append(key, params[key])
    //   }
    //   return formData
    // }]
  })
}

export function update(params, id) {
  return request({
    url: '/admin/note/' + id,
    method: 'put',
    data: params,
    // header: {'Content-Type': 'application/x-www-form-urlencoded'},
    paramsSerializer: params => {
      return qs.stringify(params, { indices: false })
    }
    // transformRequest: [data => {
    //   const formData = new FormData()
    //   for (const key in params) {
    //     formData.append(key, params[key])
    //   }
    //   return formData
    // }]
  })
}

export function list(params) {
  return request({
    url: '/admin/note',
    method: 'get',
    params: params
  })
}

export function deleteItem(id) {
  return request({
    url: '/admin/note/' + id,
    method: 'delete',
    // params: params
  })
}
